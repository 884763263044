import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Div, Flex } from '../style/styledElements';

const backdropProps = {
    position: 'fixed',
    zIndex: '1005',
    top: '0',
    left: '0',
    outline: '0',
    bg: '#000000cc',
    width: '100%',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    id: 'backdrop',
};
const bodyProps = {
    backgroundClip: 'padding-box',
    outline: '0',
    pointerEvents: 'auto',
    position: 'relative',
    width: '100%',
    // p: '6',
};
const layoutProps = {
    pointerEvents: 'none',
    // border: '1px solid rgba(0,0,0,.2)',
    center: 'true',
    mx: 'auto',
    my: '2rem',
    bg: '#f3f3f3',
    borderRadius: '5',
};


function Modal({
    children,
    show = false,
    onClose = () => {},
    size = 'md',
    heading,
    backdrop = true,
    close,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [el] = useState(document.createElement('div'));

    useEffect(() => {
        setIsOpen(show);
    }, [show]);
    
    useEffect(() => {
        document.body.appendChild(el);
        return () => {
            document.body.removeChild(el);
        };
    }, [el]);

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    const onBackDropClick = (e) => {
        if (backdrop !== 'static') {
            if (e.target.id === 'backdrop') {
                handleClose();
            }
        }
    };

    switch (size) {
        case 'sm':
            layoutProps.width = '400px';
            break;
        case 'md':
            layoutProps.width = '600px';
            break;
        case 'lg':
            layoutProps.width = '800px';
            break;

        default:
            layoutProps.width = '600px';
            break;
    }

    return (
        <>
            {isOpen &&
                createPortal(
                    <Div {...backdropProps} onClick={onBackDropClick}>
                        <Flex position='relative' center height='100%'>
                            <Div {...layoutProps} bg='transparent'>
                                {/* <Div {...headerProps}>
                                    {heading}
                                    {close !== false && (
                                        <Span
                                            cursor='pointer'
                                            onClick={handleClose}
                                            position='absolute'
                                            right='12px'
                                            top='25%'
                                        >
                                            x
                                        </Span>
                                    )}
                                </Div> */}
                                <Div {...bodyProps}>{children}</Div>
                            </Div>
                        </Flex>
                    </Div>,
                    el
                )}
        </>
    );
}

export default Modal;
