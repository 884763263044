import { Button, Div, Flex, Input, Span } from '../../style/styledElements';
import Newsletter from './Newsletter';
import { ReactComponent as Phone } from '../../asset/icons/Phone.svg';
import { ReactComponent as Mail } from '../../asset/icons/Mail.svg';
import { ReactComponent as Location } from '../../asset/icons/Location.svg';

function Footer() {
    return (
        <Div bg='black'>
            <Newsletter />
            <Flex
                pt={{ xs: '7', lg: '9' }}
                pb={{ xs: '7', lg: '0' }}
                pl={{ xs: '4', lg: '8' }}
                pr={{ xs: '5', lg: '7' }}
                alignItems='space-between'
                justifyContent='center'
                flexDirection='column'
            >
                <Div
                    width={{ md: '35%', lg: '55%' }}
                    fontSize={{ xs: 'display1', lg: '72px' }}
                    fontWeight='100'
                    color='white'
                    mb={{ xs: '6', lg: '9' }}
                >
                    KRAFT FROM THE FINEST
                </Div>
                <Div
                    width={{ lg: '30%' }}
                    ml='auto'
                    pb={{ xs: '6', lg: '8' }}
                    color='white'
                    fontWeight='300'
                >
                    <Flex mt='4'>
                        <Phone />
                        <Span ml='2'>+91 9916082680</Span>
                    </Flex>
                    <Flex mt='4'>
                        <Phone />
                        <Span ml='2'>+91 9900690059</Span>
                    </Flex>
                    <Flex mt='4'>
                        <Mail />
                        <Span ml='2'>sales@krafters.tools</Span>
                    </Flex>
                </Div>
                <Flex justifyContent='center' mb='2' textAlign = 'center' lineHeight='1.5' color='white'>
                    <Span mr = '2'>
                        <Location />
                    </Span>
                     #677, 1st Floor, Suite #481, 27th Main, 13th Cross,
                    Sector 1, HSR Layout, Bangalore, Karnataka - 560102, India.
                </Flex>
                {/* <Flex
                    width={{ xs: '100%',md:'55%', lg: '33%' }}
                    justifyContent={{ xs: 'space-around' }}
                    height='min-content'
                >
                    <Input width='70%' borderRadius='0' placeholder='Email' />
                    <Button
                        width='24%'
                        ml='3%'
                        borderRadius='0'
                        border='none'
                        py='2'
                    >
                        <Span fontWeight='600' fontSize='small'>
                            Subscribe
                        </Span>
                    </Button>
                </Flex> */}
            </Flex>

            <Div
                borderTop='1px solid'
                py={{ xs: '4', lg: '5' }}
                textAlign='center'
                fontSize='display6'
                color='gray'
            >
                © {new Date().getFullYear()} KRAFTER’S TOOLING SOLUTIONS PVT LTD.
                <br/> All rights reserved
            </Div>
        </Div>
    );
}

export default Footer;
