import styled from 'styled-components';
import { Div } from './styledElements';

const Runner = styled(Div)`
    display: flex;
    white-space: nowrap;
    will-change: transform;
    animation: ${(props) => (props.reverse ? 'marquee-left' : 'marquee-right')}
        35s linear infinite;

    @keyframes marquee-right {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-50%);
        }
    }
    @keyframes marquee-left {
        0% {
            transform: translateX(-50%);
        }
        100% {
            transform: translateX(0%);
        }
    }
`;
export { Runner };
