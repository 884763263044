// import { ReactComponent as LogoBg } from '../asset/icons/LogoBg.svg';
// import { ReactComponent as LogoSm } from '../asset/icons/Logo.svg';
import { Button, Div, Flex, Link, Nav, Span } from '../style/styledElements';
import { useMediaType } from './Responsiver';
import Dropdown from './Dropdown';
import styled from 'styled-components';
import { ReactComponent as Phone } from '../asset/icons/Phone.svg';
import { ReactComponent as Mail } from '../asset/icons/Mail.svg';
import Logo from '../asset/icons/Logo';

const Contact = styled(Button)`
    border-radius: 6px;
    border: 3px solid;
    transition: transform 0.2s;
    box-shadow: 1px 1px 0px #000000;
    &:focus,
    &:active {
        box-shadow: 8px 8px 0px #000000;
        transform: translate(-6px, -6px);
    }
    background: white;
`;

function Navbar() {
    const { isMobile } = useMediaType();
    
    return (
        <Nav
            py={{ xs: '3', md: '2' }}
            px={{ xs: '3', md: '5', lg: '8' }}
            bg='#fff'
            display='flex'
            width='100%'
            zIndex='1000'
            position='absolute'
        >
            <Link to='/'>
                <Logo size={isMobile ? 'sm': 'lg'} />
            </Link>
            <Flex ml='auto' alignItems='center'>
                <Link
                    fontSize={{ xs: 'small', md: 'normal' }}
                    to='/about'
                    color='gray'
                >
                    About
                </Link>
                <Link
                    fontSize={{ xs: 'small', md: 'normal' }}
                    to='/services'
                    color='gray'
                    ml={{ xs: '2', md: '6' }}
                >
                    Services
                </Link>
                <Link
                    fontSize={{ xs: 'small', md: 'normal' }}
                    to='/enquire'
                    color='blue'
                    ml={{ xs: '2', md: '6' }}
                >
                    Enquire Now
                </Link>
                <Dropdown
                    ml={{ xs: '2', md: '6' }}
                    ToggleBtn={
                        <Contact
                            py={{ xs: '1', md: '2' }}
                            px={{ xs: '2', md: '3' }}
                        >
                            Contact
                        </Contact>
                    }
                    Menu={
                        <Div color='blue'>
                            <Flex flexWrap='nowrap' alignItems='center'>
                                <Phone />
                                <a
                                    href='tel:+919916082680'
                                    style={{
                                        display: 'inline-block',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        marginLeft: '1em',
                                    }}
                                >
                                    +91 9916082680
                                </a>
                            </Flex>
                            <Flex flexWrap='nowrap' alignItems='center' mt='3'>
                                <Phone />
                                <a
                                    href='tel:+919900690059'
                                    style={{
                                        display: 'inline-block',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        marginLeft: '1em',
                                    }}
                                >
                                    +91 9900690059
                                </a>
                            </Flex>
                            <Flex flexWrap='nowrap' alignItems='center' mt='3'>
                                <Mail />
                                <a
                                    href='mailto:sales@krafters.tools'
                                    style={{
                                        display: 'inline-block',
                                        textDecoration: 'none',
                                        color: 'inherit',
                                        marginLeft: '1em',
                                    }}
                                >
                                    sales@krafters.tools
                                </a>
                                <Span fontSize='' ml='3'></Span>
                            </Flex>
                        </Div>
                    }
                />
            </Flex>
        </Nav>
    );
}

export default Navbar;
