import { useEffect, useRef, useState } from 'react';
import { Div } from '../style/styledElements';

function Dropdown({ ToggleBtn, Menu, ...rest }) {
    const [showDropdown, setDropdown] = useState(false);

    const menuRef = useRef(null);
    const toggleRef = useRef(null);

    useEffect(() => {
        const handler = (event) => {
            if (
                showDropdown &&
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                toggleRef.current &&
                !toggleRef.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [showDropdown]);

    return (
        <Div position='relative' {...rest}>
            <Div
                cursor='pointer'
                onClick={() => setDropdown(true)}
                ref={toggleRef}
            >
                {ToggleBtn}
            </Div>
            <Div
                position='absolute'
                top='107%'
                right ={{xs:'0%',md:''}}
                borderRadius='6'
                borderTopRightRadius = {{xs:'0'}}
                p={{xs: '3',md:'5'}}
                display={showDropdown ? 'block' : 'none'}
                m='0'
                ref={menuRef}
                bg='black'
                color='white'
                border='1px solid gray'
            >
                {Menu}
            </Div>
        </Div>
    );
}

export default Dropdown;
